<template>
    <span> {{ statusToShow }} </span>
</template>

<script>
export default {
name: "AdminHomeAppointmentStatus",
props: ['timeSlot', 'appointmentDate', 'status'],
data(){
    return{
        statusToShow: ''
    }
},
methods:{
    isCurrentTimeInSlot(dateStr, timeSlot) {
    const [yyyy, mm, dd] = dateStr.split('-').map(num => parseInt(num, 10));
    const [startTime, endTime] = timeSlot.split('-');
    const startDateTime = new Date(yyyy, mm - 1, dd, ...startTime.split(':').map(Number));
    const endDateTime = new Date(yyyy, mm - 1, dd, ...endTime.split(':').map(Number));
    const currentTime = this.getMMTTime();
    return currentTime >= startDateTime && currentTime <= endDateTime;
},

getMMTTime() {
    const nowUtc = new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000);
    const mmtTime = new Date(nowUtc.getTime() + 6.5 * 60 * 60 * 1000);
    return mmtTime;
}
},
mounted(){
  this.timeSlotRendered = this.timeSlot;
  this.statusToShow = this.status;
    if (this.isCurrentTimeInSlot(this.appointmentDate, this.timeSlot)) {
        if(this.statusToShow === 'ACTIVE') {
            this.statusToShow = 'IN PROGRESS'
        }
    }
}
}
</script>
<style>
</style>
